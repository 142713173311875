<template>
  <b-container fluid>
    <h4>Dashboard</h4>
    <hr/>
    <b-row>
      <b-col md="6" sm="6" lg="4">
        <iq-card class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:body>
            <div>
              <h6>Portfolio Overview</h6>
              <div class="pr-2 d-flex justify-content-between">
                <div>
                  <h3 class="text-success">545,309.22</h3>
                  <span>P&L</span>
                </div>
                <div class="pl-3">
                  <div>
                    <span class="text-secondary"><div style="width:10px;height:10px;background-color:#E4BB29; position:absolute;margin-top:8px;margin-left:-14px "></div> Invested</span>
                    <p>4,203.37</p>
                  </div>
                  <div>
                    <span class="text-secondary"><div style="width:10px;height:10px;background-color:#0071BB; position:absolute;margin-top:8px;margin-left:-14px "></div> Current</span>
                    <p>549,512.59</p>
                  </div>
              </div>
              </div>
            </div>
            <hr/>
            <div class="d-flex justify-content-center">
              <ApexChart element="chart-3" :chartOption="chart3"/>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" sm="6" lg="4">
        <iq-card class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:body>
            <div class="align-items-center">
              <div>
                <h6>Assets Split-up</h6>
                <div>
                  <div class="my-3">
                  <span class="title">Equities</span>
                  <Progressbar :value="15" />
                    </div>
                    <div class="my-3"><span class="title">CFD</span>
                  <Progressbar :value="65" color="warning" />
                 </div>
                  <div class="my-3"> <span class="title">Bonds</span>
                  <Progressbar :value="5" color="danger" />
                  </div>
                  <div class="my-3">
                    <span class="title">Futures</span>
                  <Progressbar :value="35" color="info" />
                  </div>
                  <div class="my-3">
                    <span class="title">Options</span>
                  <Progressbar :value="15" color="success" />
                  </div>
                  </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" sm="6" lg="4">
        <iq-card class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:body>
            <div>
              <h6>Balance sheet</h6>
                  <div class="table-responsive my-3">
                    <table class="dashboard-list">
                      <thead>
                        <tr>
                          <th>LCY</th>
                          <!-- <th>Balance in USD</th> -->
                          <th>Closing Balance</th>
                          <th>Positions</th>
                        </tr>
                      </thead>
                      <tbody v-if="balData">
                        <tr v-for="(bData,i) in balData" :key="i">
                          <td>{{bData.lcy}}</td>
                          <td>{{bData.td_closing_cash_balance}}</td>
                          <td>{{bData.unrealized_pnl}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
            </div>
        </template>
      </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" lg="12">
        <b-row>
          <b-col md="12">
            <iq-card class="iq-card-block iq-card-stretch ">
              <template v-slot:headerTitle>
                <h6 class="card-title">Portfolio Overview</h6>
              </template>
              <template v-slot:body>
                <ApexChart element="home-chart-02" :chartOption="chart2" style="min-height: 300px;"/>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import ApexChart from '../../components/core/charts/ApexChart'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { balance } from '@/services/portfolio'
export default {
  name: 'Dashboard1',
  components: { ApexChart },
  mounted () {
    core.index()
    this.balanceData(this.getCustomerAcc, this.currentDate, this.logToken)
  },
  computed: {
    ...mapGetters({
      logToken: 'Setting/logToken'
    })
  },
  data () {
    return {
      balData: '',
      currentDate: moment(new Date()).format('DD-MM-YYYY'),
      getCustomerAcc: localStorage.getItem('login'),
      chart2: {
        series: [{
          name: 'Desktops',
          data: [5, 10, 8, 15]
        }],
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        colors: ['#6ce6f4'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr']
        }
      },
      darkChart2: {
        series: [{
          name: 'Desktops',
          data: [5, 10, 8, 15]
        }],
        chart: {
          height: 150,
          type: 'line',
          foreColor: '#8c91b6',
          zoom: {
            enabled: false
          }
        },
        colors: ['#827af3'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr']
        }
      },
      chart3: {
        series: [4203.37, 549512.59],
        chart: {
          width: 300,
          height: 180,
          type: 'donut'
        },
        labels: ['Invested', 'Current'],
        dataLabels: {
          enabled: false
        },
        colors: ['#E4BB29', '#0071BB', '#6ce6f4', '#827af3', '#a09e9e', '#fbc647'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show: false
            }
          }
        }],
        legend: {
          show: false,
          position: 'top',
          offsetY: 1,
          height: 50
          // left: -75
          // verticalAlign: 'left'
        }
      },
      darkChart3: {
        series: [44, 55, 13, 33],
        chart: {
          width: 380,
          height: 180,
          foreColor: '#8c91b6',
          type: 'donut'
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#827af3', '#6ce6f4', '#a09e9e', '#fbc647'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show: false
            }
          }
        }],
        legend: {
          position: 'right',
          offsetY: 0,
          height: 150
        }
      }
    }
  },
  methods: {
    async balanceData (getCustomerAcc, currentDate, logToken) {
      const v = await balance({
        getCustomerAcc,
        currentDate,
        logToken
      })
      if (v.data.code === 0) {
        this.balData = v.data.data.result
      } else {
        localStorage.removeItem('loginData')
        this.$router.push({ path: '/auth/sign-in1' })
      }
    }
  }
}
</script>

<style>
.iq-card-body{
  flex: unset;
}

</style>
