import axios from 'axios'
import constant from '../config/constant'

const token = document.head.querySelector('meta[name="csrf-token"]') || ''

const client = axios.create({
  baseURL: constant.webBaseURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': token
  }
})
client.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    // console.log('reerror', error.response.status)
    if (error.response.status === 401) {
      localStorage.removeItem('login')
      localStorage.removeItem('loginData')
      window.location.reload()
    }
  }
)
export default client
