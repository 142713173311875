import axios from './index'

export function customerList (userData) {
  return axios.get('/api/api/customer_list?limit=100&offset=0&sort=&order=&filter={"approved_at":{"from_date":"2020-01-01"}}&api_token=' + userData.logToken, userData)
}
export function equity (userData) {
  return axios.get('/api/api/equities_report?account_number=' + userData.getCustomerAcc + '&report_date=' + userData.currentDate + '&api_token=' + userData.logToken, userData)
}
export function cfd (userData) {
  return axios.get('/api/api/cfd_report?account_number=' + userData.getCustomerAcc + '&report_date=' + userData.currentDate + '&api_token=' + userData.logToken, userData)
}
export function futureAndOptions (userData) {
  return axios.get('/api/api/future_and_options_report?account_number=' + userData.getCustomerAcc + '&report_date=' + userData.currentDate + '&api_token=' + userData.logToken, userData)
}
export function fixedIncome (userData) {
  return axios.get('/api/api/fixed_income_report?account_number=' + userData.getCustomerAcc + '&report_date=' + userData.currentDate + '&api_token=' + userData.logToken, userData)
}
export function cashActivity (userData) {
  return axios.get('/api/api/cash_activity_report?account_number=' + userData.getCustomerAcc + '&report_date=' + userData.currentDate + '&api_token=' + userData.logToken, userData)
}
export function balance (userData) {
  return axios.get('/api/api/balance_report?account_number=' + userData.getCustomerAcc + '&report_date=' + userData.currentDate + '&api_token=' + userData.logToken, userData)
}
export function tradeActivity (userData) {
  return axios.get('/api/api/trade_activity_report?account_number=' + userData.getCustomerAcc + '&report_date=' + userData.currentDate + '&api_token=' + userData.logToken, userData)
}
